/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, title }) {
  const { site, directusPageSetting } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
          }
        }
        directusPageSetting {
          name
        }
      }
    `)

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet title={title} titleTemplate={`%s | ${directusPageSetting.name}`}>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={"racing, team, danish, dansk, løb, bil, Ronnie Bremer, Jan Magnussen, sport, kørere"} />
      <meta property="fb:app_id" content={"601158117174270"} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://directus.mbracing.dk/public/uploads/_/originals/HWA_20190305_chr_witt_72.jpg" />
      <meta property="og:url" content={site.siteMetadata.url} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="pageName" content={title} />
      <meta name="fragment" content="!"></meta>
    </Helmet>
      
  
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: `https://directus.mbracing.dk/public/uploads/_/originals/HWA_20190305_chr_witt_72.jpg`
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
